<template>
  <div>
    <div>
      <div class="bussinessContent">
        <el-checkbox-group
          :max="maxNumber?maxNumber:100"
          v-model="Conventional"
          size="mini"
          @change="change"
        >
          <el-checkbox-button
           
            v-for="city in showLine"
            :label="city.id"
            :key="city.id"
            size="small"
          >{{city.name}}</el-checkbox-button>
        </el-checkbox-group>
      </div>
    </div>

    <div class="clearfix" v-if="isCenter">
      <el-button
        plain
        size="small"
        class="fr"
        @click="clickCancel"
        style="margin-left:11px"
      >{{$t('cancel')}}</el-button>
      <el-button
        plain
        size="small"
        class="fr"
        @click="clearCheck"
      >{{$t('clearup')}}</el-button>
      <!-- <el-button
        type="primary"
        size="small"
        class="fr"
        plain
        @click="checkAll"
      >{{$t('allSelect')}}</el-button> -->
      <el-button
        type="primary"
        size="small"
        class="fr"
        @click="saveLine"
        :loading="loading"
      >{{$t('confirm')}}</el-button>
    </div>
    <div class="clearfix" v-else>
      <el-button
        type="primary"
        size="small"
        class="fr"
        @click="saveLine"
        :loading="loading"
      >{{$t('confirm')}}</el-button>
      <el-button
        type="text"
        size="small"
        class="fr"
        @click="clickCancel"
        style="margin-right:21px"
      >{{$t('cancel')}}</el-button>
    </div>
  </div>
</template>

<script>
import { Industry } from "~/baseUtils/enumValue";
import { shippingLine } from "~bac/utils/enumValue";
export default {
  props: ["majorLine", "isSelect", "number",'isCenter'],
  data() {
    return {
      showLine: [],
      Conventional: [],
      loading: false,
      disabled: false,
      maxNumber:0
    };
  },
  computed: {},
  created() {},
  mounted() {
  
    if (this.LOCALE == "en") {
      this.majorLine.forEach(element => {
        element.name = element.label_en;
      });
    } else {
      this.majorLine.forEach(element => {
        element.name = element.label_zh;
      });
    }
    this.showLine = this.majorLine;
    let haveLine = this.isSelect;
    if (haveLine) {
      this.Conventional = haveLine.map(item => {
        return item.id;
      });
    }
     this.maxNumber = this.number
    if(haveLine.length>this.number){
        this.maxNumber = haveLine.length
    }
    
  },
  watch: {
    isSelect() {
      let haveLine = this.isSelect;
      if (haveLine) {
        this.Conventional = haveLine.map(item => {
          return item.id;
        });
      }
    }
  },
  methods: {
    change(e) {
      if(e.length<this.number+1){
        this.maxNumber = this.number;
      }else{
          this.maxNumber = e.length;
      }
    },
    checkAll() {
      let line = shippingLine;
      this.Conventional = line.map(item => {
        return item.id;
      });
    },
   
    clearCheck() {
      this.Conventional = [];
    },
    clickCancel() {
      this.$emit("cancelLine");
    },
    saveLine() {
        if(this.Conventional.length>5){
          this.$message({
            showClose: true,
            message: this.LOCALE == "en" ? "Select up to five" : "最多选择五个",
            type: "warning"
          });
        return;
      }
      this.loading = true;

      this.$emit("saveLine", this.Conventional);
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
.bussinessListTitle {
  line-height: 50px;
  border-bottom: 1px solid #f2f2f2;
}
.bussinessContent {
  padding: 15px 0;
}
</style>
